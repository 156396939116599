import { Modal } from '@wordpress/components'
import { useTranslation } from 'react-i18next'

export const LimitReachedModal = ({ closeModal }) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('sidebar.autooptimize.modal.title')}
      clasName="mktseo-modal--black"
      onRequestClose={closeModal}
    >
      <p>{t('sidebar.autooptimize.modal.text')}</p>
    </Modal>
  )
}
