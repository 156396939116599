import { Frame, Loader, Show } from '#components'
import { ContentCheck } from '#components/ContentCheck'
import { useScan } from '#hooks'
import { sendMetaTags } from '#services/metaTags'
import { FocusKeyword } from '#tasks/FocusKeyword'
import { createBlock } from '@wordpress/blocks'
import { Button, Flex, PanelBody } from '@wordpress/components'
import { useEffect, useState } from '@wordpress/element'
import { useFieldArray, useForm } from 'react-hook-form'
import { Trans as T, useTranslation } from 'react-i18next'
import { Illustration, ILLUSTRATIONS } from '#components'

import {
  BlockControls,
  BlockEditorProvider,
  BlockList,
} from '@wordpress/block-editor'
import { dispatch, select } from '@wordpress/data'

import './styles.css'

export const AutoOptimize = ({
  setIsManualOptimization,
  setIsOptimized,
  pop,
}) => {
  const { t } = useTranslation()
  const { data: scan = null } = useScan(pop)

  if (!scan.tasks) return <Loader />

  const addSuggestions = (scan = {}) => {
    const { tasks } = scan

    const removeEmptySuggestions = (block) => block.suggestion
    const addSuggestionsIfNotPass = (tasks) => {
      const [first] = tasks
      return tasks.some((task) => !task.pass) ? first.suggestions : null
    }

    const images =
      tasks['imgaltsingle'].suggestions?.map((image) => ({
        block: 'image',
        title: 'sidebar.autooptimize.image.title',
        suggestion: image.altText,
        img: image.img,
        group: 'content',
        value: false,
      })) || []

    const content = [
      {
        block: 'title',
        title: 'sidebar.autooptimize.h1.title',
        suggestion: addSuggestionsIfNotPass([tasks['keyonh1']]),
        group: 'content',
        value: false,
      },
      {
        block: 'subtitle',
        title: 'sidebar.autooptimize.h2.title',
        suggestion: addSuggestionsIfNotPass([tasks['keyonhx']]),
        group: 'content',
        value: false,
      },
      {
        block: 'content',
        title: 'sidebar.autooptimize.words.title',
        suggestion: addSuggestionsIfNotPass([tasks['lowcontentsingle']]),
        group: 'content',
        value: false,
      },
      ...images,
    ].filter(removeEmptySuggestions)

    const meta = [
      {
        block: 'metatitle',
        title: 'sidebar.autooptimize.metatitle.title',
        suggestion: addSuggestionsIfNotPass([
          tasks['notitlesingle'],
          tasks['keyontitle'],
          tasks['longtitlesingle'],
        ]),
        group: 'meta',
        value: false,
      },
      {
        block: 'metadescription',
        title: 'sidebar.autooptimize.metadescription.title',
        suggestion: addSuggestionsIfNotPass([tasks['nodescsingle']]),
        group: 'meta',
        value: false,
      },
    ].filter(removeEmptySuggestions)

    return {
      content,
      meta,
    }
  }

  const values = addSuggestions(scan)

  const [isBusy, setIsBusy] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])

  const { handleSubmit, control, watch } = useForm({
    values: values,
  })

  const {
    fields: contentFields,
    update: updateContent,
    remove: removeContent,
  } = useFieldArray({
    control,
    shouldUnregister: true,
    name: 'content',
  })

  const {
    fields: metaFields,
    update: updateMeta,
    remove: removeMeta,
  } = useFieldArray({
    control,
    shouldUnregister: true,
    name: 'meta',
  })

  const updateTitle = (title) => {
    dispatch('core/editor').editPost({ title })
  }

  const updateSubtitle = (subtitle) => {
    dispatch('core/block-editor').insertBlock(
      createBlock('core/heading', { content: subtitle, level: 2 }),
      0,
    )
  }

  const addContent = (content) => {
    dispatch('core/block-editor').insertBlock(
      createBlock('core/paragraph', { content }),
    )
  }

  const updateImageAlt = (id, text) => {
    dispatch('core/block-editor').updateBlockAttributes(id, { alt: text })
  }

  const updateImages = (images) => {
    const imageBlocks = select('core/block-editor')
      .getBlocks()
      .filter((block) => block.name === 'core/image')

    images.forEach((image) => {
      const { clientId } = imageBlocks.find(
        (block) => block.attributes.url === image.img,
      )

      updateImageAlt(clientId, image.suggestion)
    })
  }

  const onSubmit = ({ content, meta }) => {
    const plainData = [...content, ...meta]

    if (!plainData.length) {
      return
    }

    const noneSelected = plainData.every((field) => !field.value)

    const changes = noneSelected
      ? plainData.map((block) => ({ ...block, value: true }))
      : plainData.filter((field) => field.value)

    const newTitle = changes.find((change) => change.block === 'title')
    if (newTitle) {
      updateTitle(newTitle.suggestion)
    }

    const newSubtitle = changes.find((change) => change.block === 'subtitle')

    if (newSubtitle) {
      updateSubtitle(newSubtitle.suggestion)
    }

    const newContent = changes.find((change) => change.block === 'content')

    if (newContent) {
      addContent(newContent.suggestion)
    }

    const newImages = changes.filter((change) => change.block === 'image')

    if (newImages.length) {
      updateImages(newImages)
    }

    const newMetaTitle = changes.find((change) => change.block === 'metatitle')
    const newMetaDescription = changes.find(
      (change) => change.block === 'metadescription',
    )

    if (newMetaTitle || newMetaDescription) {
      setIsBusy(true)
      sendMetaTags({
        postId: pop.id,
        data: {
          title: newMetaTitle.suggestion,
          description: newMetaDescription.suggestion,
        },
      })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsBusy(false)
        })
    }

    setIsOptimized(true)
    setIsManualOptimization(true)
  }

  useEffect(() => {
    const result = [...watch('content'), ...watch('meta')].filter(
      (field) => field.value,
    )
    setSelectedOptions(result)
  }, [watch('content'), watch('meta')])

  return (
    <section className="auto-optimize">
      <Frame className="auto-optimize__buttons">
        <p>{t('sidebar.autooptimize.cta.post.text')}</p>
        <Flex gap="3">
          <Button
            variant="secondary"
            isBusy={isBusy}
            disabled={isBusy}
            onClick={() => setIsManualOptimization(true)}
          >
            <T i18nKey="sidebar.autooptimize.cta.post.button.back" />
          </Button>
          <Show when={contentFields.length || metaFields.length}>
            <Button
              isBusy={isBusy}
              disabled={isBusy}
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              <T
                i18nKey={
                  selectedOptions?.length
                    ? 'sidebar.autooptimize.cta.post.button.apply.number'
                    : 'sidebar.autooptimize.cta.post.button.apply'
                }
                values={{ selectedNumber: selectedOptions?.length }}
              />
            </Button>
          </Show>
        </Flex>
      </Frame>
      <Show when={contentFields.length || metaFields.length}>
        <FocusKeyword.Selected pop={pop} />
      </Show>
      <Show when={contentFields.length}>
        <PanelBody title={t('sidebar.autooptimize.content.title')}>
          {contentFields.map((field, index) => (
            <ContentCheck
              key={field.id}
              title={t(field.title)}
              content={field.suggestion}
              checked={field.value}
              onChange={(value) => {
                updateContent(index, { ...field, value })
              }}
              onCloseButton={() => removeContent(index)}
            />
          ))}
        </PanelBody>
      </Show>
      <Show when={metaFields.length}>
        <PanelBody title={t('sidebar.autooptimize.meta.title')}>
          {metaFields.map((field, index) => (
            <ContentCheck
              key={field.id}
              title={t(field.title)}
              content={field.suggestion}
              checked={field.value}
              onChange={(value) => {
                updateMeta(index, { ...field, value })
              }}
              onCloseButton={() => removeMeta(index)}
            />
          ))}
        </PanelBody>
      </Show>
      <Show when={!contentFields.length && !metaFields.length}>
        <section className="auto-optimize__empty">
          <Illustration name={ILLUSTRATIONS.OYP.EMPTY_MONO} />
          <p>
            <b>{t('sidebar.autooptimize.empty.title')}</b>
          </p>
          <p>{t('sidebar.autooptimize.empty.text')}</p>
        </section>
      </Show>
    </section>
  )
}
