import { selectors } from '#services'
import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '#services'

export const formatTasks = (tasks) => {
  if (!tasks) {
    return null
  }

  return tasks?.reduce(
    (acc, { id, pass, aiFeature, suggestions }) => ({
      ...acc,
      [id]: {
        pass,
        aiFeature,
        suggestions,
      },
    }),
    {},
  )
}

const buildHtml = (title, description, body, h1) => {
  const sanitizedTitle = title && title.replace(/<[^>]*>?/gm, '')
  const sanitizedDescription =
    description && description.replace(/<[^>]*>?/gm, '')
  const sanitizedH1 = h1 && h1.replace(/<[^>]*>?/gm, '')
  const titleFormatted =
    sanitizedTitle && sanitizedTitle.length > 0
      ? `<title>${sanitizedTitle}</title>`
      : ''
  const h1Formatted = sanitizedH1 && sanitizedH1.length > 0 ? sanitizedH1 : ''
  const descriptionFormatted =
    sanitizedDescription && sanitizedDescription.length > 0
      ? `<meta name="description" content="${sanitizedDescription}">`
      : ''

  return `
    <!DOCTYPE html>
    <html>
        <head>
            ${titleFormatted}
            ${descriptionFormatted}
        </head>
        <body>
            <h1>${h1Formatted}</h1>
            ${body}
        </body>
    </html>

`
}

const createBodyToCheck = (data) => {
  if (!data) return null

  const { data: post } = data

  return {
    html_body: btoa(
      unescape(
        encodeURIComponent(
          buildHtml(
            post.postMetaFields?.[selectors.title]?.[0] || '',
            post.postMetaFields?.[selectors.description]?.[0] || '',
            post.content?.rendered
              .replace(/(\r\n|\n|\r)/gm, ' ')
              .replace(/\s+/g, ' '),
            post.title?.rendered,
          ),
        ),
      ),
    ),
    keyword: post.postMetaFields?.[selectors.keyword]?.[0] || '',
    slug: post.slug,
  }
}

export const useScan = ({ id, type, status }) => {
  const {
    data: post,
    isValidating: isPostValidating,
    mutate,
  } = useSWRImmutable(
    id && status === 'publish' ? endpoints.pop.get({ id, type }) : null,
    request,
  )

  const { data: metaKeyword } = useSWRImmutable(
    id ? endpoints.meta.read({ popId: id, key: 'keyword' }) : null,
    request,
  )

  const { data: check, isValidating: isCreateCheckValidating } =
    useSWRImmutable(
      post && metaKeyword?.data?.data
        ? endpoints.checks.create(createBodyToCheck(post))
        : null,
      request,
    )

  const {
    data,
    isLoading,
    isValidating: isCheckingValidating,
    error,
  } = useSWRImmutable(
    check ? endpoints.checks.get(check.data.data.id) : null,
    request,
  )

  const { isValidating: isScoreValidating } = useSWRImmutable(
    data
      ? endpoints.meta.create({
          postId: id,
          data: { score: data?.data.data.attributes.score },
        })
      : null,
    request,
  )
  const tasks = formatTasks(data?.data.data.attributes.tasks)

  return {
    data: { tasks, score: data?.data.data.attributes.score },
    isError: error,
    isLoading,
    mutate,
    isValidating:
      isPostValidating ||
      isCreateCheckValidating ||
      isCheckingValidating ||
      isScoreValidating,
  }
}
